import clsx from 'clsx';
import Link from 'next/link';
import { FC, forwardRef } from 'react';

interface Props {
  title: string;
  link: string;
  isSelected: boolean;
}

export const MainMenuItem: FC<Props> = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const { title, link, isSelected, children } = props;

  return (
    <Link href={link}>
      <a
        ref={ref}
        className={clsx(
          'text-white flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg',
          isSelected ? ['bg-gray-900'] : ['bg-none', 'hover:bg-gray-700'],
        )}
      >
        <span className="sr-only">{title}</span>
        {children}
      </a>
    </Link>
  );
});

MainMenuItem.displayName = 'MainMenuItem';

import { z } from 'zod';

export const UserSchema = z.object({
  id: z.number(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  username: z.string(),
  role: z.enum(['admin', 'user', 'content_manager', 'customer_support_manager']),
});

export type User = z.infer<typeof UserSchema>;

export const getUserDisplayName = (user: User): string => {
  if (user.first_name || user.last_name) {
    return [user.first_name, user.last_name].filter(Boolean).join(' ');
  }

  return user.username;
};

import clsx from 'clsx';
import { FC } from 'react';

import styles from './Spinner.module.scss';

export const Spinner: FC = () => (
  <div className={styles.wrapper}>
    <div className={clsx(styles.child, styles.bounce1, 'bg-brand-500')} />
    <div className={clsx(styles.child, styles.bounce2, 'bg-brand-500')} />
    <div className={clsx(styles.child, 'bg-brand-500')} />
  </div>
);

import Router from 'next/router';
import { FC } from 'react';

import { Loading } from '@/common/layouts/Loading';
import { useCurrentUser } from '@/common/queries/useCurrentUser';

export const LoggedWrapper: FC = ({ children }) => {
  const userData = useCurrentUser();

  if (!userData.isLoading && !userData.data) {
    Router.push(`${process.env.NEXT_PUBLIC_IDENTITY_SERVICE_URL}/signup`);
  }

  if (userData.isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

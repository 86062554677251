import { useSetNavigationCategory } from '@/common/hooks/useSetNavigationCategory';
import { MainLayout } from '@/common/layouts/Main';

export default function Home() {
  useSetNavigationCategory(null);

  return (
    <MainLayout>
      {/* Primary column */}
      <section
        aria-labelledby="primary-heading"
        className="min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last"
      />
    </MainLayout>
  );
}

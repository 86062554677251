import { useEffect } from 'react';
import { Shortcuts } from 'shortcuts';

type ShortcutHandler = (event: KeyboardEvent) => boolean | void;

export const useShortcut = (shortcut: string, handler: ShortcutHandler): void => {
  useEffect(() => {
    let shortcuts: Shortcuts;
    import('shortcuts').then((module) => {
      shortcuts = new module.Shortcuts({
        capture: true,
        shouldHandleEvent: (event: KeyboardEvent): boolean =>
          (event.target as HTMLElement).nodeName !== 'INPUT' && (event.target as HTMLElement).nodeName !== 'TEXTAREA',
      });
      shortcuts.add([{ shortcut, handler }]);
    });

    return () => {
      if (shortcuts) {
        shortcuts.reset();
      }
    };
  }, [shortcut, handler]);
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { useApi } from '@/common/hooks/useApi';
import { User, UserSchema } from '@/common/models/user';
import { $currentUserApi } from '@/common/store/currentUser';

export const CURRENT_USER_QUERY_KEY = 'currentUser';

export const useCurrentUser = (): UseQueryResult<User> => {
  const api = useApi();
  return useQuery(
    CURRENT_USER_QUERY_KEY,
    async () => {
      try {
        const response = await api.get('/api/users/current');
        return UserSchema.parse(response.data.data);
      } catch (e) {
        if (e?.response?.status === 401) {
          return undefined;
        }

        throw e;
      }
    },
    {
      onSuccess: (user) => {
        $currentUserApi.setUser(user);
      },
    },
  );
};

import Tippy from '@tippyjs/react';
import { useStore } from 'effector-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { MainMenuItem } from '@/common/components/MainMenuItem';
import { useShortcut } from '@/common/hooks/useShortcut';
import { LoggedWrapper } from '@/common/LoggedWrapper';
import { $currentNavSection } from '@/common/store/currentNavSection';

export const MainLayout: FC = ({ children }) => {
  const router = useRouter();

  // Shortcut: Open Tasks
  useShortcut('O T', () => {
    router.push('/app/tasks');
  });

  // Shortcut: Open Activities
  useShortcut('O A', () => {
    router.push('/app/activities');
  });

  // Shortcut: Create Task
  useShortcut('C T', () => {
    router.push('/app/tasks/create');
  });

  const currentSection = useStore($currentNavSection);

  return (
    /* const [isOpen, setIsOpen] = useState(false);

    const handleToggleUserMenu = () => {
      setIsOpen((prev) => !prev);
    };

    const currentUser = useStore($currentUser);

    const dropDownMenuRef = useRef<HTMLDivElement>(null);
    useClickAway(dropDownMenuRef, () => {
      setIsOpen(false);
    }); */

    <LoggedWrapper>
      <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
        {/* Top nav */}
        <header className="flex-shrink-0 relative h-16 bg-white flex items-center" style={{ display: 'none' }}>
          {/* Menu button area */}
          <div className="absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 md:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-600"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          {/* Desktop nav area */}
          {/* <div className="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <div className="max-w-2xl relative text-gray-400 focus-within:text-gray-500">
                <label htmlFor="search-text" className="sr-only">
                  Tasks Search...
                </label>
                <input
                  id="search-text"
                  type="search"
                  placeholder="Tasks Search..."
                  className="block w-full border-transparent pl-12 placeholder-gray-500 focus:border-transparent sm:text-sm focus:ring-0"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
              <div className="flex items-center space-x-8">
                <div className="relative inline-block text-left">
                  <button
                    type="button"
                    onClick={handleToggleUserMenu}
                    className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-600"
                    id="menu-1"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open User Menu</span>
                    {currentUser ? <UserAvatar user={currentUser} /> : null}
                  </button>

                  <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <div
                      ref={dropDownMenuRef}
                      className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-1"
                    >
                      <div className="py-1" role="none">
                        <a
                          href={`${process.env.NEXT_PUBLIC_IDENTITY_SERVICE_URL}/profile`}
                          className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                          Go to Geecko ID
                        </a>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div> */}
        </header>

        {/* Bottom section */}
        <div className="min-h-0 flex-1 flex overflow-hidden">
          {/* Narrow sidebar */}
          <nav aria-label="Sidebar" className="hidden md:block md:flex-shrink-0 md:bg-gray-800 md:overflow-y-auto">
            {/* Logo area */}
            <Link href="/">
              <a className="flex items-center justify-center h-16 w-16 bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-600 md:w-20">
                <svg className="h-12 w-auto" viewBox="0 0 1005 1005" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="1005" height="1005" rx="192" fill="#fff" />
                  <path
                    d="M599.546 347.316v-97.612h-96.553c-108.622 0-195.788 86.915-195.788 195.223 0 106.971 87.166 195.223 195.788 195.223 34.866 0 68.392-9.36 97.894-25.406v74.88h97.894V347.316h-99.235zm-28.161 165.805c-17.433 17.383-41.572 28.08-68.392 28.08-53.64 0-97.894-44.125-97.894-97.611 0-26.743 10.728-50.811 28.161-68.194 17.434-17.383 41.572-28.08 68.392-28.08h6.705c24.138 1.337 46.936 12.034 63.028 28.08 17.433 17.383 28.161 41.451 28.161 68.194 0 28.08-10.728 52.148-28.161 69.531z"
                    fill="#040D2B"
                  />
                  <path d="M601.063 688.02H380.699v97.932h220.364V688.02z" fill="#000" />
                </svg>
              </a>
            </Link>
            <div className="relative w-20 flex flex-col p-3 space-y-3">
              <Tippy content="Tasks" placement="right" offset={[0, 20]}>
                <MainMenuItem title="Tasks" link="/app/tasks" isSelected={currentSection === 'tasks'}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                </MainMenuItem>
              </Tippy>
            </div>
            <div className="relative w-20 flex flex-col p-3 space-y-3">
              <Tippy content="Activities" placement="right" offset={[0, 20]}>
                <MainMenuItem title="Activities" link="/app/activities" isSelected={currentSection === 'activities'}>
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                    />
                  </svg>
                </MainMenuItem>
              </Tippy>
            </div>
          </nav>

          {/* Main area */}
          <main className="min-w-0 flex-1 border-t border-gray-200">{children}</main>
        </div>
      </div>
    </LoggedWrapper>
  );
};
